<template>
  <div class="margin-navbar">
    <div class="d-flex flex-column w-100 font-body text-dark p-4">
      <h1 class="mt-3 mb-4">
        La mia formazione
      </h1>
      <div
        class="img-background mb-5"
        :style="{ backgroundImage: backgroundImage }"
      ></div>
      <p>
        Il mio percorso di terapeuta è iniziato attraverso la
        <strong>Laurea triennale in Fisioterapia</strong> presso il
        <strong>polo universitario di Pietra Ligure</strong>, associato all’
        <strong>ospedale Santa Corona</strong>, considerato internazionalmente
        un’eccellenza nelle terapie riabilitative.
      </p>
      <p>
        Gli studi universitari mi hanno permesso di approfondire e soddisfare il
        crescente interesse verso la magnifica complessità dell'Essere Umano,
        interesse che mi ha portato a iscrivermi subito dopo la Laurea ad una
        <strong>scuola di Osteopatia</strong> e parallelamente intraprendere una
        formazione in ambito <strong>Neurologico</strong>.
      </p>
      <p>
        Successivamente ho indirizzato i miei studi sul
        <strong>Tessuto Connettivo</strong>, conosciuto anche come
        <strong>“Fascia”</strong>. Una parte del corpo che come dice il nome
        connette le varie parti corporee creando così un ponte tra la struttura,
        principalmente trattata dall'<router-link to="/osteopatia">osteopata</router-link >, e la
        funzione, più prerogativa del
        <router-link  to="/fisioterapista">fisioterapista</router-link >.
      </p>
      <p>
        Questo mi ha dato le conoscenze e gli strumenti per poter lavorare con
        entrambi i punti di vista senza dividere settorialmente osteopatia da
        fisioterapia ma anzi comprendere in un trattamento ciò che di buono
        possono offrire entrambe le specializzazioni, modellando di volta in
        volta il mio intervento sul caso specifico.
      </p>
    </div>
    <div class="d-flex w-100 justify-content-end p-5">
      <router-link to="/" tag="button" class="btn">
        Torna alla home
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

const image = require("@/assets/elia-formazione.jpg");
console.log(image);

export default {
  name: "Formazione",
  components: {},
  metaInfo: {
    title: "Elia Giovinazzo - La mia formazione",
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      {
        name: "description",
        content:
          "Studio di Osteopatia e Fisioterapia del Dott. Elia Giovinazzo. Professionista della salute a Genova. Riabilitazione, Trattamento e Prevenzione.",
      },
      {
        property: "og:title",
        content: "Elia Giovinazzo - Osteopatia  e Fisioterapia - Genova",
      },
      { property: "og:site_name", content: "Elia Giovinazzo" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  data() {
    return {
      backgroundImage: 'url("' + image + '")',
    };
  },
};
</script>

<style scoped>
.hero-image {
  height: 30vh;
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
